import {
  disableBodyScroll,
  enableBodyScroll
} from "./chunk.WDF6H473.js";
import {
  JbOverlay
} from "./chunk.5C7B4R5Q.js";
import {
  Spaceable
} from "./chunk.G3SZKTQJ.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  Animatable
} from "./chunk.VRR2P3PQ.js";
import {
  recenterNavItems
} from "./chunk.WXNWU2SV.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-contact-overlay/jb-contact-overlay.scss?inline
var jb_contact_overlay_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  background-color: white;
  display: block;
  min-height: 100%;
  padding-bottom: 126px;
  padding-top: 42px;
}
@media (min-width: 600px) {
  :host {
    padding-top: 12px;
  }
}
@media (min-width: 1281px) {
  :host {
    padding-top: 2px;
  }
}

iframe {
  border: 0;
  height: 0;
  width: 100%;
}`;

// src/components/jb-contact-overlay/jb-contact-overlay.ts
var JbContactOverlay = class extends Initiable(Spaceable(Animatable(h))) {
  async firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    await this.updateComplete;
    this.dispatchEvent(
      new CustomEvent("jb-tracking", {
        detail: {
          event: "ce_form",
          event_version: "v2",
          component_name: "contact-overlay",
          form_step_name: "form open",
          form_step_nr: "1",
          form_subject: this.formToDisplay.trackingSubject || "",
          form_type: "contact"
        },
        bubbles: true,
        composed: true
      })
    );
    this.contentChildren = [];
    const formHeader = this._form.shadowRoot.querySelector(".formheader");
    if (formHeader) {
      this.contentChildren.push(...Array.from(formHeader.children));
    }
    if (this._form.shadowRoot.querySelector("h3")) {
      this.contentChildren.push(this._form.shadowRoot.querySelector("h3"));
    }
    this.contentChildren.push(...Array.from(this._form.children));
    this.contentChildren.forEach((element) => {
      element.style.setProperty("opacity", "0");
    });
    document.addEventListener("jb-overlay:close", () => {
      this._prepareToClose();
    });
  }
  static async open(event) {
    const customEvent = event;
    if (!customEvent.detail.contactOverlayForm) return;
    if (customEvent.detail.clickEvent) {
      customEvent.detail.clickEvent.preventDefault();
      customEvent.detail.clickEvent.stopPropagation();
    }
    customEvent.stopPropagation();
    const contactOverlay = new JbContactOverlay().init({
      props: {
        formIdentifier: customEvent.detail.contactFormUid,
        formToDisplay: customEvent.detail.contactOverlayForm,
        withSpacing: true
      }
    });
    new JbOverlay().init({
      props: {
        color: "white",
        hasLogo: true,
        logoLink: customEvent.detail.logoLink,
        svgLogo: customEvent.detail.svgLogo,
        newAnimation: true
      },
      childNodes: [contactOverlay]
    }).stack();
    disableBodyScroll(contactOverlay, { allowTouchMove: () => true });
    recenterNavItems();
    await contactOverlay.animateWrapper();
    await contactOverlay.animateWrapperControls();
    contactOverlay.animateContent();
  }
  _captureTracking(evt) {
    evt.stopPropagation();
  }
  _getContentSpacingProperties() {
    return { grid: "legacy", colsMdUp: 8, colsLgUp: 6 };
  }
  async _prepareToClose() {
    await this.hide();
    const { parentElement } = this;
    const grandparentElement = parentElement.parentElement;
    if (parentElement.tagName === "JB-OVERLAY") {
      parentElement.unstack();
    }
    if (grandparentElement.tagName === "JB-OVERLAY") {
      grandparentElement.unstack();
    }
    enableBodyScroll(this);
    recenterNavItems();
  }
  render() {
    return ke`
      <jb-form
        .action=${this.formToDisplay.action}
        .additionalData=${this.formToDisplay.additionalData}
        .bordered=${this.formToDisplay.bordered}
        .contentElements=${this.formToDisplay.contentElements}
        .formHeader=${this.formToDisplay.formHeader}
        .formIdentifier=${this.formToDisplay.formIdentifier}
        .generalConcern=${this.formToDisplay.generalConcern}
        .multiformSubline=${this.formToDisplay.multiformSubline}
        .recipient="${this.formToDisplay.recipient}"
        .recipientPhone=${this.formToDisplay.recipientPhone}
        .recipientPosition=${this.formToDisplay.recipientPosition}
        .trackingSubject=${this.formToDisplay.trackingSubject}
        .validationFieldname=${this.formToDisplay.validationFieldname}
        displayGrid
        @jb-tracking=${this._captureTracking}
      ></jb-form>
    `;
  }
};
JbContactOverlay.styles = r(jb_contact_overlay_default);
__decorateClass([
  n()
], JbContactOverlay.prototype, "formIdentifier", 2);
__decorateClass([
  n({ type: Object })
], JbContactOverlay.prototype, "formToDisplay", 2);
__decorateClass([
  n()
], JbContactOverlay.prototype, "svgLogo", 2);
__decorateClass([
  e("jb-form")
], JbContactOverlay.prototype, "_form", 2);
JbContactOverlay = __decorateClass([
  customElement("jb-contact-overlay")
], JbContactOverlay);
document.body.addEventListener("jb-link:clicked", JbContactOverlay.open);

export {
  JbContactOverlay
};
