import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-teaser-header/jb-teaser-header.scss?inline
var jb_teaser_header_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
header p, header h3 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

header h3 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 22px);
  line-height: var(--theme-typo-h3-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  header h3 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 26px);
    line-height: var(--theme-typo-h3-desktop-lineheight, 36px);
  }
}

header p {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 16px);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 26px);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

header {
  position: relative;
}
@media (max-width: 959px) {
  header.no_image h3 {
    margin-top: var(--teaser-header-h3-margin-top, 0);
  }
}
header h3 {
  margin-bottom: var(--teaser-header-h3-margin-bottom, 24px);
  margin-top: var(--teaser-header-h3-margin-top, 16px);
}
header p {
  display: var(--display-teaser-text, block);
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
}
header p::before {
  background: linear-gradient(to right, rgba(var(--body-background-color, 255, 255, 255), 0), rgba(var(--body-background-color, 255, 255, 255), 1) 83%);
  content: "";
  margin: auto;
  max-width: 115px;
  position: absolute;
  right: 0;
  text-align: right;
  width: 50%;
}
header .teaser-text-1-lines {
  max-height: calc(1 * 26px);
}
header .teaser-text-1-lines::before {
  height: 26px;
  top: calc((1 - 1) * 26px);
}
header .teaser-text-2-lines {
  max-height: calc(2 * 26px);
}
header .teaser-text-2-lines::before {
  height: 26px;
  top: calc((2 - 1) * 26px);
}
header .teaser-text-3-lines {
  max-height: calc(3 * 26px);
}
header .teaser-text-3-lines::before {
  height: 26px;
  top: calc((3 - 1) * 26px);
}
header .teaser-text-4-lines {
  max-height: calc(4 * 26px);
}
header .teaser-text-4-lines::before {
  height: 26px;
  top: calc((4 - 1) * 26px);
}
header .teaser-text-5-lines {
  max-height: calc(5 * 26px);
}
header .teaser-text-5-lines::before {
  height: 26px;
  top: calc((5 - 1) * 26px);
}
header:hover jb-picture {
  --transform-image: scale(1.05);
}
header:hover jb-thumbnail {
  --transform-thumbnail: scale(1.05);
  --trasnform-tag: scale(1.05);
}
header.gallery jb-thumbnail {
  --gradient-thumbnail: none;
}
header.gallery:hover jb-picture {
  --transform-image: none;
}
header.gallery:hover jb-thumbnail {
  --transform-thumbnail: none;
}`;

// src/components/jb-teaser-header/jb-teaser-header.ts
var JbTeaserHeader = class extends h {
  constructor() {
    super(...arguments);
    this.headline = "";
    this.maxLines = 3;
  }
  _renderPreview() {
    if (this.thumbnail) {
      return ke`<jb-thumbnail
        .backgroundPicture="${this.thumbnail.backgroundPicture}"
        .videoSrc="${this.thumbnail.videoSrc}"
        .aspect="${this.thumbnail.aspect}"
        .type="${this.thumbnail.type}"
      ></jb-thumbnail>`;
    }
    if (this.picture) {
      return ke`<jb-picture
        .alt=${this.picture.alt}
        .fit=${"cover"}
        .imgData=${this.picture.imgData}
        .lazy=${this.picture.lazy}
        .aspect=${this.gallery ? this.picture.aspect : "aspect_3by2"}
      ></jb-picture>`;
    }
    return null;
  }
  _renderTeaser() {
    if (this.teaser) {
      return ke`<p class="teaser-text-${this.maxLines}-lines">${this.teaser}</p>`;
    }
    return null;
  }
  render() {
    return ke`<header class="${Rt({ no_image: !this.thumbnail && !this.picture, gallery: !!this.gallery })}">
      ${this._renderPreview()} ${this.headline ? ke`<h3>${this.headline}</h3>` : D}
      ${this.teaser ? this._renderTeaser() : D}
    </header>`;
  }
};
JbTeaserHeader.styles = r(jb_teaser_header_default);
__decorateClass([
  n({ type: Boolean })
], JbTeaserHeader.prototype, "gallery", 2);
__decorateClass([
  n()
], JbTeaserHeader.prototype, "headline", 2);
__decorateClass([
  n({ type: Number })
], JbTeaserHeader.prototype, "maxLines", 2);
__decorateClass([
  n({ type: Object })
], JbTeaserHeader.prototype, "picture", 2);
__decorateClass([
  n()
], JbTeaserHeader.prototype, "teaser", 2);
__decorateClass([
  n({ type: Object })
], JbTeaserHeader.prototype, "thumbnail", 2);
JbTeaserHeader = __decorateClass([
  customElement("jb-teaser-header")
], JbTeaserHeader);

export {
  JbTeaserHeader
};
