import {
  JBCookie,
  querySelectorAllShadow,
  smoothScroll
} from "./chunk.WXNWU2SV.js";
import {
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r2 as r
} from "./chunk.BBN2ZB42.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/mixins/Linkable.ts
var LinkableEvents = class {
  constructor() {
    this["jb-link:clicked"] = void 0;
    this["jb-link:initialized"] = void 0;
  }
};
function registerLinkClickHandlers(linkTag, linkableComponent = void 0) {
  linkTag.addEventListener("click", (clickEvent) => {
    const textContent = linkableComponent?.getTextContent?.() ?? linkTag.innerText;
    const detail = { linkTag, clickEvent, linkableComponent, textContent, noGenericLinkTracking: false };
    if (linkTag.dataset.jblinkdata !== void 0) {
      const jblinkdata = JSON.parse(linkTag.dataset.jblinkdata);
      Object.assign(detail, jblinkdata);
    }
    if (linkableComponent?.link?.href && linkableComponent?.link?.href !== "#") {
      const linkFragment = linkableComponent.link.href.split("#")[1];
      if (typeof linkFragment !== "undefined") {
        const targets = querySelectorAllShadow(`#${linkFragment}`);
        if (targets.length === 1) {
          smoothScroll(targets[0]);
        }
      }
    }
    const customEvent = new CustomEvent("jb-link:clicked", {
      detail: {
        ...detail,
        trigger: "Linkable"
      },
      bubbles: true,
      composed: true
    });
    clickEvent.target?.dispatchEvent(customEvent);
  });
}
function jbLinkClickedEventToJbTrackingEvent(jbLinkClickedEvent, preconfiguredDetails) {
  const { type, linkTag } = jbLinkClickedEvent.detail;
  let trackingType;
  if (jbLinkClickedEvent.detail.contactIframeUrl || jbLinkClickedEvent.detail.contactOverlayForm) {
    trackingType = "contactOverlay";
  } else if (jbLinkClickedEvent.detail.publicationOverlay) {
    trackingType = "publicationDownloadOverlay";
  } else if (jbLinkClickedEvent.detail.cookieSettings) {
    trackingType = "cookieSettings";
  } else {
    trackingType = type || "internal";
  }
  let detail;
  switch (trackingType) {
    case "contactOverlay":
      detail = {
        event: "ce_interaction",
        interaction_effect: "open",
        interaction_element: "Overlay Links",
        interaction_result: `Contact | ${jbLinkClickedEvent.detail.contactFormUid}`,
        interaction_type: "click"
      };
      break;
    case "download":
      detail = {
        event: "ce_download",
        file_name: jbLinkClickedEvent.detail.downloadFileName.split(".").shift(),
        file_type: jbLinkClickedEvent.detail.downloadFileName.split(".").pop().toLocaleLowerCase()
      };
      break;
    case "external":
    case "internal":
      detail = {
        event: "ce_interaction",
        interaction_effect: "navigation",
        interaction_element: type === "internal" ? "Internal Links" : "External Links",
        interaction_result: new URL(linkTag.href).href,
        interaction_type: "click"
      };
      break;
    case "social":
      detail = {
        event: "ce_interaction",
        interaction_effect: "navigation",
        interaction_element: "Social Links",
        interaction_result: new URL(linkTag.href).href,
        interaction_type: "click"
      };
      break;
    case "publicationDownloadOverlay":
      detail = {
        event: "ce_interaction",
        interaction_effect: "open",
        interaction_element: "Overlay Links",
        interaction_result: `Download | ${jbLinkClickedEvent.detail.publicationOverlay.title}`,
        interaction_type: "click"
      };
      break;
    default:
      return;
  }
  jbLinkClickedEvent.stopPropagation();
  document.body.dispatchEvent(
    new CustomEvent("jb-link:clicked", {
      detail: {
        ...jbLinkClickedEvent.detail,
        noGenericLinkTracking: true
      },
      bubbles: true,
      composed: true
    })
  );
  return new CustomEvent("jb-tracking", {
    detail: { ...detail, ...preconfiguredDetails, event_version: "v2" },
    bubbles: true,
    composed: true
  });
}
function initializeLink(linkTag, linkableComponent = void 0) {
  if (!linkTag) {
    return;
  }
  if (!linkTag.dataset.linkInitialized) {
    registerLinkClickHandlers(linkTag, linkableComponent);
  }
  linkTag.dataset.linkInitialized = "true";
  const customEvent = new CustomEvent("jb-link:initialized", {
    bubbles: true,
    composed: true,
    detail: { ...JSON.parse(linkTag.dataset.jblinkdata || "{}"), linkTag, linkableComponent }
  });
  linkTag.dispatchEvent(customEvent);
}
function getMarketSpecificHref(marketUris) {
  if (marketUris === void 0) {
    return void 0;
  }
  const userMarket = parseInt(JBCookie.getI18nCookieData().market, 10);
  if (userMarket === 0) {
    return void 0;
  }
  const matchingEntry = marketUris.find((entry) => entry.marketUid === userMarket);
  if (!matchingEntry) {
    return void 0;
  }
  return matchingEntry.href;
}
function getLinkDefaultValues() {
  return {};
}
function setLinkAttributes(element, link) {
  if (!element || !link) {
    return;
  }
  const defaultValues = getLinkDefaultValues();
  const {
    data = defaultValues.data,
    download = defaultValues.download,
    href = defaultValues.href,
    name = defaultValues.name,
    target = defaultValues.target
  } = link;
  const marketSpecificHref = getMarketSpecificHref(link.marketUris);
  element.setAttribute("href", marketSpecificHref || href || "#");
  if (name) {
    element.setAttribute("aria-label", name);
  } else {
    element.removeAttribute("aria-label");
  }
  if (download) {
    element.setAttribute("download", "");
  } else {
    element.removeAttribute("download");
  }
  if (target) {
    element.setAttribute("target", target);
  } else {
    element.removeAttribute("target");
  }
  if (data) {
    element.setAttribute("data-jblinkdata", JSON.stringify(data));
  } else {
    element.removeAttribute("data-jblinkdata");
  }
}
function setLink(element, link) {
  setLinkAttributes(element, link);
  initializeLink(element);
}
function getLinkIcon(type) {
  if (type === "mail") {
    return ke`<jb-mail-icon></jb-mail-icon>`;
  }
  if (type === "download") {
    return ke`<jb-download-icon></jb-download-icon>`;
  }
  if (type === "in-page") {
    return ke`<jb-in-page-icon></jb-in-page-icon>`;
  }
  if (type === "internal") {
    return ke`<jb-internal-icon></jb-internal-icon>`;
  }
  if (type === "external") {
    return ke`<jb-external-icon></jb-external-icon>`;
  }
  return "";
}
function Linkable(baseClass) {
  class LinkableMixin extends baseClass {
    update(changedProperties) {
      super.update(changedProperties);
      if (changedProperties.has("link")) {
        this._linkTags?.forEach((linkTag) => {
          setLinkAttributes(linkTag, this.link);
          initializeLink(linkTag, this);
        });
      }
    }
    getTextContent() {
      return null;
    }
    /**
     * Override this method in your web component if you want to define default link values.
     * { target: '_blank' } will make your link open in a new window, if the link data don't explicitly set a different target
     * { download: true } will set a download attribute on your link, if the link data don't explicitly set it to false
     */
    _getLinkDefaultValues() {
      return {};
    }
  }
  __decorateClass([
    n({ type: Object })
  ], LinkableMixin.prototype, "link", 2);
  __decorateClass([
    r("a[data-linkable]")
  ], LinkableMixin.prototype, "_linkTags", 2);
  return LinkableMixin;
}

export {
  LinkableEvents,
  jbLinkClickedEventToJbTrackingEvent,
  initializeLink,
  setLink,
  getLinkIcon,
  Linkable
};
