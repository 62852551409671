import {
  Linkable,
  setLink
} from "./chunk.ABOYTYJO.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-section-sitemap/jb-section-sitemap.scss?inline
var jb_section_sitemap_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
li a {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

li a {
  font-size: var(--theme-typo-uppercase-700-fontsize, 11px);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 1px);
  line-height: var(--theme-typo-uppercase-700-lineheight, 16px);
  text-transform: uppercase;
}

h2 {
  font-size: var(--theme-typo-h2-section-title-mobile-fontsize, 24px);
  letter-spacing: var(--theme-typo-h2-section-title-mobile-letterspacing, 2px);
  line-height: var(--theme-typo-h2-section-title-mobile-lineheight, 36px);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-section-title-desktop-fontsize, 31px);
    letter-spacing: var(--theme-typo-h2-section-title-desktop-letterspacing, 4px);
    line-height: var(--theme-typo-h2-section-title-desktop-lineheight, 40px);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
nav {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: var(--theme-grid-template-columns, var(--_grid-template-columns));
  width: 100%;
}
@media (min-width: 960px) {
  nav {
    --_grid-gap: 40px;
  }
}

section {
  --_max-width: calc(100% - (2 * 6.68%));
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] minmax(0, var(--theme-grid-max-width, var(--_max-width))) [main-end] minmax(0, 1fr) [full-end];
  margin-inline: auto;
}
@media (min-width: 600px) {
  section {
    --_max-width: calc(100% - (2 * 8.35%));
  }
}
@media (min-width: 960px) {
  section {
    --_max-width: calc(100% - (2 * 6.955%));
  }
}
@media (min-width: 1437px) {
  section {
    --_max-width: 1437px;
  }
}

:host {
  color: var(--host-color, #141e55);
}

section {
  background-color: rgba(113, 124, 125, 0.1);
  padding: var(--spacing-xl) 0;
  padding-inline-end: var(--compensate-scrollbar);
}
@media (min-width: 600px) {
  section {
    padding: 48px 0;
  }
}
@media (min-width: 960px) {
  section {
    padding: 64px 0;
  }
}
@media (min-width: 1281px) {
  section {
    padding: var(--spacing-2xl) 0;
  }
}

nav {
  grid-column: main;
}

h2 {
  grid-column: 1/13;
  margin-block-end: var(--spacing-sm);
  text-align: center;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out 0s;
}
@media (min-width: 600px) {
  h2 {
    grid-column: 1/7;
    text-align: start;
  }
}
@media (min-width: 960px) {
  h2 {
    grid-column: 2/6;
  }
}
@media (min-width: 1281px) {
  h2 {
    margin-block-end: 0;
  }
}
h2:hover {
  opacity: 0.6;
}
h2 a {
  color: #141e55;
  text-decoration: none;
}

ul {
  grid-column: 1/13;
  list-style-type: none;
}
@media (min-width: 600px) {
  ul {
    grid-column: 7/13;
  }
}
@media (min-width: 960px) {
  ul {
    column-count: 2;
    column-gap: var(--_grid-gap, 40px);
    grid-column: 6/12;
  }
}

li {
  margin-block-end: var(--spacing-sm);
  text-align: center;
}
@media (min-width: 600px) {
  li {
    text-align: start;
  }
}
li a {
  color: #141e55;
  opacity: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out 0s;
}
li a:hover {
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out 0s;
}

@media (min-width: 600px) {
  .intranet h2 {
    text-align: left;
  }
}
@media (min-width: 960px) {
  .intranet h2 {
    grid-column: 1/6;
  }
}`;

// src/components/jb-section-sitemap/jb-section-sitemap.ts
var JbSectionSitemap = class extends Linkable(h) {
  _renderItems(items) {
    return items.map((item) => {
      const anchor = document.createElement("a");
      if (item.link) setLink(anchor, item.link);
      anchor.innerText = item.text;
      return ke`<li>${anchor}</li>`;
    });
  }
  render() {
    return ke`
      <section class="${this.intranetStyle ? "intranet" : D}">
        <nav>
          <h2><a data-linkable>${this.text}</a></h2>
          <ul>
            ${this._renderItems(this.items)}
          </ul>
        </nav>
      </section>
    `;
  }
};
JbSectionSitemap.styles = r(jb_section_sitemap_default);
__decorateClass([
  n({ type: Boolean })
], JbSectionSitemap.prototype, "intranetStyle", 2);
__decorateClass([
  n({ type: Array })
], JbSectionSitemap.prototype, "items", 2);
__decorateClass([
  n()
], JbSectionSitemap.prototype, "text", 2);
JbSectionSitemap = __decorateClass([
  customElement("jb-section-sitemap")
], JbSectionSitemap);

export {
  JbSectionSitemap
};
