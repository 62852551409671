import {
  Linkable,
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.ABOYTYJO.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r as r2
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-image-page-teaser-item/jb-image-page-teaser-item.scss?inline
var jb_image_page_teaser_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h3 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h3 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 22px);
  line-height: var(--theme-typo-h3-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 26px);
    line-height: var(--theme-typo-h3-desktop-lineheight, 36px);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.col {
  padding: 0;
  position: relative;
  width: 100%;
}

.aspect_3by2 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.aspect_3by2 > *,
.aspect_3by2 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
.aspect_3by2::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(1 / 1 * 100%);
  position: relative;
  width: 100%;
}
@media (min-width: 960px) {
  .aspect_3by2 {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .aspect_3by2 > *,
  .aspect_3by2 iframe {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
  }
  .aspect_3by2::before {
    content: "";
    display: block;
    height: 0;
    padding-bottom: calc(2 / 3 * 100%);
    position: relative;
    width: 100%;
  }
}

.inner {
  height: 100%;
  min-height: 160px;
  overflow: hidden;
  width: 100%;
  --rgb-primary: 255, 255, 255;
}
@media (hover: hover) and (pointer: fine) {
  .inner:hover .bg {
    transform: scale(1.15);
  }
  .inner:hover .content {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.bg {
  inset: 0;
  position: absolute;
  transition: all 300ms ease-in-out;
}

.content {
  align-items: start;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: flex-end;
  padding: 30px;
  position: absolute;
  transition: all 300ms ease-in-out;
}
@media (min-width: 600px) {
  .content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.4) 100%);
  }
}

h3 {
  color: #fff;
  margin-bottom: 8px;
  transition: all 200ms ease-out 100ms;
}
@media (min-width: 600px) {
  h3 {
    bottom: 70px;
    left: 30px;
    margin-bottom: 0;
    position: absolute;
    width: calc(100% - 60px);
  }
}

jb-picture {
  height: 100%;
}`;

// src/components/jb-image-page-teaser-item/jb-image-page-teaser-item.ts
var JbImagePageTeaserItem = class extends Linkable(h) {
  constructor() {
    super(...arguments);
    this._hovering = false;
  }
  _hoverLinkIn() {
    this._hovering = true;
  }
  _hoverLinkOut() {
    this._hovering = false;
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "image-page-teaser"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <a
        @jb-link:clicked=${this._tracking}
        @mouseout=${this._hoverLinkOut}
        @mouseover=${this._hoverLinkIn}
        data-linkable
      >
        <div class="col aspect_3by2">
          <div class="inner ${this.teaserType}">
            <div class="bg">
              ${ke`<jb-picture
                .alt=${this.bgImage?.alt}
                .imgData=${this.bgImage?.imgData}
                ?lazy=${this.bgImage?.lazy}
                fit="cover"
              ></jb-picture>`}
            </div>
            <div class="content">
              <h3>${this.headline}</h3>
              <div class="content_wrapper">
                ${this.button ? ke`<jb-button
                      .forceHover=${this._hovering}
                      .text=${this.button.text}
                      .variant=${this.button.variant}
                      .white=${true}
                    ></jb-button>` : D}
              </div>
            </div>
          </div>
        </div>
      </a>
    `;
  }
};
JbImagePageTeaserItem.styles = r(jb_image_page_teaser_item_default);
__decorateClass([
  n({ type: Object })
], JbImagePageTeaserItem.prototype, "bgImage", 2);
__decorateClass([
  n({ type: Object })
], JbImagePageTeaserItem.prototype, "button", 2);
__decorateClass([
  n()
], JbImagePageTeaserItem.prototype, "headline", 2);
__decorateClass([
  n({ type: Boolean })
], JbImagePageTeaserItem.prototype, "randomized", 2);
__decorateClass([
  n()
], JbImagePageTeaserItem.prototype, "teaserType", 2);
__decorateClass([
  r2()
], JbImagePageTeaserItem.prototype, "_hovering", 2);
JbImagePageTeaserItem = __decorateClass([
  customElement("jb-image-page-teaser-item")
], JbImagePageTeaserItem);

export {
  JbImagePageTeaserItem
};
