import {
  Linkable
} from "./chunk.ABOYTYJO.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-teaser-meta/jb-teaser-meta.scss?inline
var jb_teaser_meta_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
div .timezone, div, div a {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

div a {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
div a {
  border: 1px solid rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  color: #141e55 !important;
  padding: 4px 9px;
  text-decoration: none;
  text-transform: uppercase;
  transition: border 0.7s ease-in-out 0s;
}
div a:hover {
  border: 1px solid #141e55;
  transition: border 0.7s ease-in-out 0s;
}
div .category {
  margin-top: var(--category-spacing-top, 25px);
  width: 100%;
}
div jb-calendar-icon {
  margin-right: 10px;
}
div .timezone {
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 16px;
  line-height: 1rem;
  align-items: center;
  color: #141e55;
  display: flex;
  margin-inline-start: 25px;
  text-transform: uppercase;
}
div .timezone jb-globe-icon {
  --stroke-color: #141e55;
  display: inline-flex;
  margin-inline-end: 10px;
  width: 17px;
}
div jb-time {
  margin-left: 12px;
}
@media (min-width: 960px) {
  div jb-time {
    margin-left: 25px;
  }
}`;

// src/components/jb-teaser-meta/jb-teaser-meta.ts
var JbTeaserMeta = class extends Linkable(h) {
  _renderDate() {
    if (this.date) {
      return ke`<jb-calendar-icon></jb-calendar-icon><jb-date date=${this.date}></jb-date>`;
    }
    return null;
  }
  _renderTime() {
    if (this.time) {
      return ke`<jb-time time=${this.time}></jb-time>`;
    }
    return null;
  }
  _renderTimeZone() {
    if (this.timeZone) {
      return ke`<div class="timezone">
        <jb-globe-icon></jb-globe-icon>
        ${this.timeZone}
      </div>`;
    }
    return null;
  }
  render() {
    return ke`<div>
      ${this._renderDate()} ${this._renderTime()} ${this._renderTimeZone()}
      ${this.category && ke`<div class="category"><a data-linkable>${this.category}</a></div>`}
    </div>`;
  }
};
JbTeaserMeta.styles = r(jb_teaser_meta_default);
__decorateClass([
  n()
], JbTeaserMeta.prototype, "category", 2);
__decorateClass([
  n()
], JbTeaserMeta.prototype, "date", 2);
__decorateClass([
  n()
], JbTeaserMeta.prototype, "time", 2);
__decorateClass([
  n()
], JbTeaserMeta.prototype, "timeZone", 2);
JbTeaserMeta = __decorateClass([
  customElement("jb-teaser-meta")
], JbTeaserMeta);

export {
  JbTeaserMeta
};
