import {
  JbI18nData
} from "./chunk.SE5MQEAG.js";
import {
  bindLinkHandler,
  showCookieConsentOnPageLoad
} from "./chunk.JFNWOIMP.js";
import {
  ae
} from "./chunk.Q25FCJRM.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  JBCookie,
  mergeDeep
} from "./chunk.WXNWU2SV.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r as r2
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-cookie-consent/jb-cookie-consent.scss?inline
var jb_cookie_consent_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 21px;
  line-height: 34px;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 24px;
    line-height: 36px;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  --host-color: rgb(255, 255, 255);
  display: block;
}

section {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}

.container {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
  align-items: center;
  background-color: var(--background-color, #141e55);
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-block: 40px;
  padding-inline: 20px;
}
@media (min-width: 600px) {
  .container {
    padding-inline: 40px;
  }
}
@media (min-width: 960px) {
  .container {
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 40px;
    padding-inline-start: 98px;
  }
}
@media (min-width: 1281px) {
  .container {
    padding-block: 40px 70px;
    padding-inline: 98px 40px;
  }
}

@media (min-width: 960px) {
  .text-container {
    padding-block: 30px;
  }
}

h2 {
  color: #fff;
  margin-bottom: 10px;
}

jb-richtext {
  margin-bottom: 30px;
}

jb-select,
jb-dropdown {
  align-self: end;
  width: 116px;
}
@media (min-width: 960px) {
  jb-select,
  jb-dropdown {
    align-self: unset;
    flex-basis: 116px;
    flex-shrink: 0;
  }
}

jb-button,
jb-button-primary,
jb-button-secondary,
jb-button-tertiary {
  width: 100%;
}
@media (min-width: 960px) {
  jb-button,
  jb-button-primary,
  jb-button-secondary,
  jb-button-tertiary {
    margin-bottom: 0;
    width: auto;
  }
}

@media print {
  :host {
    display: none;
  }
}`;

// src/components/jb-cookie-consent/jb-cookie-consent.ts
var JbCookieConsent = class extends Initiable(h) {
  constructor() {
    super(...arguments);
    this._hide = false;
    this._open = false;
  }
  static create(data) {
    return new JbCookieConsent().init({ props: data, childNodes: [] });
  }
  close() {
    this._open = false;
  }
  firstUpdated() {
    window.setTimeout(() => {
      this._prepareCookieSettingsComponent().then();
    }, 50);
  }
  getCookieSettings() {
    return this._cookieSettingsComponent;
  }
  open() {
    this._open = true;
    window.addEventListener("load", () => {
      this.dispatchEvent(
        new CustomEvent("jb-cookie-consent:open", {
          bubbles: true,
          composed: true
        })
      );
    });
  }
  showCookieSettingsPopup() {
    if (!this._cookieSettingsComponent) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("jb-popup:transition", {
        bubbles: true,
        composed: true,
        detail: {
          nextChild: this._cookieSettingsComponent
        }
      })
    );
  }
  _getLanguageOptions() {
    const i18nDataComponent = JbI18nData.find();
    if (!i18nDataComponent) {
      return [];
    }
    return i18nDataComponent.getI18nDataLanguageOptionsForCountry(JBCookie.getI18nCookieData().country);
  }
  _getLanguageSelectOptions() {
    if (!this._languageSelectOptions) {
      const i18nDataComponent = JbI18nData.find();
      if (!i18nDataComponent) {
        return [];
      }
      const languageOptions = this._getLanguageOptions();
      this._languageSelectOptions = [];
      languageOptions?.forEach((languageOption) => {
        const language = i18nDataComponent.getLanguageByIsoCode(languageOption.language);
        if (!language) {
          return;
        }
        this._languageSelectOptions.push({
          label: language.shortLabel || language.label,
          value: languageOption.language
        });
      });
    }
    return this._languageSelectOptions;
  }
  _handleButtonClick(button, event) {
    event.preventDefault();
    event.stopPropagation();
    if (button.setsConsentFor) {
      this._cookieSettingsComponent?.setConsentFor(button.setsConsentFor);
    }
    if (button.opensCookieSettings) {
      this.showCookieSettingsPopup();
    } else {
      this._saveLanguageSelection();
      JBCookie.deleteCookie("allow_tracking_cookies");
      JBCookie.deleteCookie("allow_marketing_cookies");
      JBCookie.setCookie("hide_cookie_banner", "1");
      JBCookie.setCookie("show_cookie_banner", "0");
      JBCookie.setCookie("cookie_banner_suppressed", "0");
      this.dispatchEvent(new CustomEvent("jb-popup:close", { bubbles: true, composed: true }));
    }
  }
  _handleLanguageChange(event) {
    event.stopPropagation();
    if (event.detail.newValue && event.detail.newValue !== this._selectedLanguage) {
      this._selectedLanguage = event.detail.newValue;
      this._prepareCookieSettingsComponent().then();
    }
  }
  async _prepareCookieSettingsComponent() {
    const currentLanguage = this._selectedLanguage || JBCookie.getI18nCookieData().languageIso2;
    const effectiveCookieSettings = mergeDeep(
      {},
      this.cookieSettingsPerLanguage.__default,
      this.cookieSettingsPerLanguage[currentLanguage]
    );
    const { JbCookieSettings } = await import("../components/jb-cookie-settings/jb-cookie-settings.js");
    this._cookieSettingsComponent = JbCookieSettings.create(effectiveCookieSettings);
  }
  _saveLanguageSelection() {
    if (!this._selectedLanguage || this._selectedLanguage === JBCookie.getI18nCookieData().languageIso2) {
      return;
    }
    JBCookie.setI18nCookieData({ languageIso2: this._selectedLanguage });
    const selectedOption = this._getLanguageOptions().find(
      (languageOption) => languageOption.language === this._selectedLanguage
    );
    if (!selectedOption) {
      return;
    }
    window.location.href = selectedOption.url;
  }
  render() {
    const languageOptions = this._getLanguageSelectOptions();
    const currentLanguage = this._selectedLanguage || JBCookie.getI18nCookieData().languageIso2 || (languageOptions?.length ? languageOptions[0].value : void 0) || "";
    const content = this.content[currentLanguage] || this.content.__default;
    const headline = this.headlineLabels[currentLanguage] || this.headlineLabels.__default;
    if (!this._open) {
      return ke``;
    }
    return ke`
      <section>
        <div class="container">
          ${languageOptions && languageOptions.length > 0 ? ke`
                <jb-dropdown
                  @jb-select:changed=${this._handleLanguageChange}
                  inverted
                  .options=${languageOptions}
                  .value=${currentLanguage}
                ></jb-dropdown>
              ` : D}
          <div class="text-container">
            <h2>${headline}</h2>
            <jb-richtext .size=${"small"}>${ae(content)}</jb-richtext>
            <jb-button-panel>
              ${this.buttons.map(
      (button) => ke`
                  ${button.isPrimaryAction ? ke`
                        <jb-button-primary
                          @click=${this._handleButtonClick.bind(this, button)}
                          inverted
                        >
                          ${button.labels[currentLanguage] || button.labels.__default}
                        </jb-button-primary>
                      ` : ke`
                        <jb-button-secondary
                          @click=${this._handleButtonClick.bind(this, button)}
                          inverted
                        >
                          ${button.labels[currentLanguage] || button.labels.__default}
                        </jb-button-secondary>
                      `}
                `
    )}
            </jb-button-panel>
          </div>
        </div>
      </section>
    `;
  }
};
JbCookieConsent.styles = r(jb_cookie_consent_default);
__decorateClass([
  n({ type: Array })
], JbCookieConsent.prototype, "buttons", 2);
__decorateClass([
  n({ type: Object })
], JbCookieConsent.prototype, "content", 2);
__decorateClass([
  n({ type: Object })
], JbCookieConsent.prototype, "cookieSettingsPerLanguage", 2);
__decorateClass([
  n({ type: Object })
], JbCookieConsent.prototype, "headlineLabels", 2);
__decorateClass([
  n({ type: Boolean })
], JbCookieConsent.prototype, "showOnPageLoad", 2);
__decorateClass([
  r2()
], JbCookieConsent.prototype, "_hide", 2);
__decorateClass([
  r2()
], JbCookieConsent.prototype, "_open", 2);
__decorateClass([
  r2()
], JbCookieConsent.prototype, "_selectedLanguage", 2);
JbCookieConsent = __decorateClass([
  customElement("jb-cookie-consent")
], JbCookieConsent);
bindLinkHandler();
showCookieConsentOnPageLoad();

export {
  JbCookieConsent
};
