import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-share-bar/jb-article-share-bar.scss?inline
var jb_article_share_bar_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.button-share .button-wrapper .title {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  display: flex;
  gap: var(--spacing-3xs);
  justify-content: flex-start;
}
@media (min-width: 600px) {
  :host {
    justify-content: flex-end;
  }
}

.button-share {
  block-size: 40px;
  display: flex;
}
.button-share .button-wrapper {
  align-items: center;
  display: flex;
  inline-size: auto;
  justify-content: flex-end;
  position: relative;
  user-select: none;
}
.button-share .button-wrapper .title {
  color: #141e55;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

ul {
  list-style-type: none;
}
ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  inline-size: 40px;
  justify-content: center;
  transition: transform 0.3s ease-in-out 0s;
}
ul li:hover {
  transform: scale(1.5);
}
ul.icons {
  align-items: center;
  display: flex;
  justify-content: center;
}
ul.icons jb-facebook-icon {
  --icon-width: 15.6px;
  --icon-height: 15.6px;
}
ul.icons jb-mail-icon {
  --icon-size: 18.5px;
  --icon-width: 18.5px;
  --icon-height: 18.5px;
}
ul.icons jb-x-icon {
  --icon-width: 15px;
  --icon-height: 13.6px;
}
ul.icons jb-linkedin-icon {
  --icon-width: 14.6px;
  --icon-height: 15px;
}`;

// src/components/jb-article-share-bar/jb-article-share-bar.ts
var JbArticleShareBar = class extends h {
  _fbClick(event) {
    event.stopPropagation();
    this._trackClick("facebook");
  }
  _liClick(event) {
    event.stopPropagation();
    this._trackClick("linkedin");
  }
  _mailClick(event) {
    event.stopPropagation();
    this._trackClick("mail");
  }
  _trackClick(interaction_result) {
    this.dispatchEvent(
      new CustomEvent("jb-tracking", {
        detail: {
          event: "ce_interaction",
          event_version: "v2",
          component_name: "article-share-bar",
          interaction_effect: "share",
          interaction_element: "icon",
          interaction_result,
          interaction_type: "click"
        },
        bubbles: true,
        composed: true
      })
    );
  }
  _twClick(event) {
    event.stopPropagation();
    this._trackClick("twitter");
  }
  render() {
    return ke`
      <div class="button-share">
        <div class="button-wrapper">${this.title ? ke`<span class="title">${this.title}</span>` : D}</div>
      </div>
      <ul class="icons">
        <li>
          <a
            aria-label="${this.ariaLabelShareOnFacebook ?? "Facebook"}"
            @click=${this._fbClick}
            href="https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.url)}"
            target="_blank"
          >
            <jb-facebook-icon></jb-facebook-icon>
          </a>
        </li>
        <li>
          <a
            aria-label="${this.ariaLabelShareOnMail ?? "Mail"}"
            @click=${this._mailClick}
            href="mailto:?subject=[${this.mailSubject}]%20${encodeURI(this.articleTitle)}&amp;body=${this.url}"
          >
            <jb-mail-icon></jb-mail-icon>
          </a>
        </li>
        <li>
          <a
            aria-label="${this.ariaLabelShareOnX ?? "X"}"
            @click=${this._twClick}
            href="https://twitter.com/share?text=${encodeURI(`${this.articleTitle} ${this.url}`)}"
            target="_blank"
          >
            <jb-x-icon></jb-x-icon>
          </a>
        </li>
        <li>
          <a
            aria-label="${this.ariaLabelShareOnLinkedin ?? "LinkedIn"}"
            @click=${this._liClick}
            href="https://www.linkedin.com/shareArticle/?mini=true&amp;url=${encodeURI(this.url)}&amp;title=${encodeURI(
      this.articleTitle
    )}&amp;summary=&amp;source=web"
            target="_blank"
          >
            <jb-linkedin-icon></jb-linkedin-icon>
          </a>
        </li>
      </ul>
    `;
  }
};
JbArticleShareBar.styles = r(jb_article_share_bar_default);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "ariaLabelShareOnFacebook", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "ariaLabelShareOnLinkedin", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "ariaLabelShareOnMail", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "ariaLabelShareOnX", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "articleTitle", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "mailSubject", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "title", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "url", 2);
JbArticleShareBar = __decorateClass([
  customElement("jb-article-share-bar")
], JbArticleShareBar);

export {
  JbArticleShareBar
};
